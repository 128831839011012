import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/apps/nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}