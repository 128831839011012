<template>
   <NuxtLayout :name="layout">
     <div class="grid min-h-full place-content-center place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
       <div class="text-center" v-if="error && error.statusCode == 503">
         <p class="text-lg font-semibold text-center text-primary-500">
           {{ error.statusCode }}
         </p>
         <h1 class="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
           {{ $t(error.message) }}
         </h1>
         <p class="mt-4">
           {{ $t('Please try again in a few minutes.') }}
         </p>
       </div>
       <div class="text-center" v-else-if="error && error.statusCode == 404">
         <p class="text-lg font-semibold text-primary">
           {{ error.statusCode }}
         </p>
         <h1 class="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl">
           {{ $t('Page not found') }}
         </h1>
         <p class="mt-6 text-base leading-7 text-gray-600">
           {{ $t("Sorry, we couldn't find the page you're looking for.") }}
         </p>
         <div class="mt-10 flex items-center justify-center gap-x-6">
           <NuxtLink
             :to="$localePath('/')"
             class="btn btn-primary"
           >
             {{ $t('Go back home') }}
           </NuxtLink>
         </div>
       </div>
       <div class="text-center" v-else>
         <p class="text-base font-semibold text-primary">
           {{ error }}
         </p>
         <div class="mt-10 flex items-center justify-center gap-x-6">
           <v-button :to="$localePath('/')">
             {{ $t('Go back home') }}
           </v-button>
         </div>
       </div>
     </div>
   </NuxtLayout>
</template>
<script>
export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const router = useRouter();
    const config = useRuntimeConfig();

    const isMaintenance = computed(() => {
      return config.public.maintenance_mode;
    });

    const layout = computed(() => {
      return isMaintenance.value ? 'maintenance' : 'default';
    });

    return {
      router,
      layout
    };
  },
};
</script>
