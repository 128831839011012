import revive_payload_client_Kt45hCplIJQ_Tc9HJgMg6WuPRgujgj4uhjoJZ9dfsJM from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qkaro9tofLPFZ92RFrD2ypzcmE_lqn4dv0z435_M4aY from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_l62mzzjQmuwRmvNcV_EzU4v5U4mGr7TmCG57qtYdn48 from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_43c_i8bvuPfLp26V_ja5QinVhiOwmP4Qv0jXJTvSd8o from "/app/node_modules/.pnpm/nuxt-site-config@2.2.21_vite@6.2.3_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ZwvfPUovFZ1sqtscB0UF_S4UgxkKXn6tzvUHXyl61bk from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rFDRzXB8ItI8Uc3_3u5wPpYcgtmPupoc2C1Ixn5Yeb4 from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qHmM6_Qd6jn236rq6qU_6LlUSSgjPh4cNK9ucBdkbZU from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_T1gBc_lmKqwDTcUUo5_CLPl_siUIcbbBUSUcdN6Qi_0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fA9SbE3EsWd5QN0hJVZ_CudTJ8kDr89cuD_0C0zOwmw from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.8.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_1_GBhyhZu_AQSHZML6t94LVf0Ytt_32dTyReZ_T0eMA from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_NBSZB21rSJ4O80klySXLjlNKeWxTFF3Lz9DNWIkN0Wg from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.16.2_vue@3.5.13/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU from "/app/apps/nuxt/.nuxt/formkitPlugin.mjs";
import switch_locale_path_ssr_1O3_4F1auEj69dJ5tvJFH2HLhunudYJHnz6XITll_Vw from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_eslint@8.57.1_rollup@4.37.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_b6giR4UZBMbKSIckh0WT78WVzOKCrOiciJsLK5D8deY from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_eslint@8.57.1_rollup@4.37.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_Gk_c9nDOYdpe0hBlRcVf7tFvb5MzQfL0S8FF14NxRlo from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_eslint@8.57.1_rollup@4.37.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import ssg_detect_v8eKEBsMIv4zdMiGh7Yef34nA4PugtYSwmgKXZ2_MSs from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_eslint@8.57.1_rollup@4.37.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_Kt45hCplIJQ_Tc9HJgMg6WuPRgujgj4uhjoJZ9dfsJM,
  unhead_qkaro9tofLPFZ92RFrD2ypzcmE_lqn4dv0z435_M4aY,
  router_l62mzzjQmuwRmvNcV_EzU4v5U4mGr7TmCG57qtYdn48,
  _0_siteConfig_43c_i8bvuPfLp26V_ja5QinVhiOwmP4Qv0jXJTvSd8o,
  payload_client_ZwvfPUovFZ1sqtscB0UF_S4UgxkKXn6tzvUHXyl61bk,
  navigation_repaint_client_rFDRzXB8ItI8Uc3_3u5wPpYcgtmPupoc2C1Ixn5Yeb4,
  check_outdated_build_client_qHmM6_Qd6jn236rq6qU_6LlUSSgjPh4cNK9ucBdkbZU,
  chunk_reload_client_T1gBc_lmKqwDTcUUo5_CLPl_siUIcbbBUSUcdN6Qi_0,
  plugin_vue3_fA9SbE3EsWd5QN0hJVZ_CudTJ8kDr89cuD_0C0zOwmw,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_1_GBhyhZu_AQSHZML6t94LVf0Ytt_32dTyReZ_T0eMA,
  plugin_NBSZB21rSJ4O80klySXLjlNKeWxTFF3Lz9DNWIkN0Wg,
  formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU,
  switch_locale_path_ssr_1O3_4F1auEj69dJ5tvJFH2HLhunudYJHnz6XITll_Vw,
  route_locale_detect_b6giR4UZBMbKSIckh0WT78WVzOKCrOiciJsLK5D8deY,
  i18n_Gk_c9nDOYdpe0hBlRcVf7tFvb5MzQfL0S8FF14NxRlo,
  ssg_detect_v8eKEBsMIv4zdMiGh7Yef34nA4PugtYSwmgKXZ2_MSs
]