
// @ts-nocheck
import locale_en_46js_8d653b82 from "#nuxt-i18n/8d653b82";
import locale_de_46js_060521e9 from "#nuxt-i18n/060521e9";
import locale_it_46js_98ff5f80 from "#nuxt-i18n/98ff5f80";
import locale_es_46js_3892a261 from "#nuxt-i18n/3892a261";
import locale_fr_46js_7fdd2e2b from "#nuxt-i18n/7fdd2e2b";

export const localeCodes =  [
  "en",
  "de",
  "it",
  "es",
  "fr"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46js_8d653b82",
      load: () => Promise.resolve(locale_en_46js_8d653b82),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_46js_060521e9",
      load: () => Promise.resolve(locale_de_46js_060521e9),
      cache: true
    }
  ],
  it: [
    {
      key: "locale_it_46js_98ff5f80",
      load: () => Promise.resolve(locale_it_46js_98ff5f80),
      cache: true
    }
  ],
  es: [
    {
      key: "locale_es_46js_3892a261",
      load: () => Promise.resolve(locale_es_46js_3892a261),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_fr_46js_7fdd2e2b",
      load: () => Promise.resolve(locale_fr_46js_7fdd2e2b),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/8a20bc9d" /* webpackChunkName: "config_i18n_46config_46ts_8a20bc9d" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: false
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      label: "English",
      files: [
        "/app/apps/nuxt/i18n/lang/en.js"
      ]
    },
    {
      code: "de",
      label: "Deutsch",
      files: [
        "/app/apps/nuxt/i18n/lang/de.js"
      ]
    },
    {
      code: "it",
      label: "Italiano",
      files: [
        "/app/apps/nuxt/i18n/lang/it.js"
      ]
    },
    {
      code: "es",
      label: "Español",
      files: [
        "/app/apps/nuxt/i18n/lang/es.js"
      ]
    },
    {
      code: "fr",
      label: "Français",
      files: [
        "/app/apps/nuxt/i18n/lang/fr.js"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix",
  lazy: false,
  langDir: "lang",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: true,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: false
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "config",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    label: "English",
    files: [
      {
        path: "/app/apps/nuxt/i18n/lang/en.js",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    label: "Deutsch",
    files: [
      {
        path: "/app/apps/nuxt/i18n/lang/de.js",
        cache: undefined
      }
    ]
  },
  {
    code: "it",
    label: "Italiano",
    files: [
      {
        path: "/app/apps/nuxt/i18n/lang/it.js",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    label: "Español",
    files: [
      {
        path: "/app/apps/nuxt/i18n/lang/es.js",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    label: "Français",
    files: [
      {
        path: "/app/apps/nuxt/i18n/lang/fr.js",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/