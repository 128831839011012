
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as cartBwrOFrt7pYLzgHIC15JSBUBnh1PUsS2t2FkNXvc2cA4Meta } from "/app/apps/nuxt/pages/cart.vue?macro=true";
import { default as homeZhsI9BXyOb7pS1l8q7qY5FH8S0meM1v4GSlfnqsjCPAMeta } from "/app/apps/nuxt/pages/home.vue?macro=true";
import { default as pageqeZYq6GLRIgMkpiOf0Gp1LjxK74nU2l6XRbc1sDKI6MMeta } from "/app/apps/nuxt/pages/page.vue?macro=true";
import { default as errorlYAwEU60CyTdCMaZzVsGXWKgiQian4K8suaOXbrtZqoMeta } from "/app/apps/nuxt/pages/error.vue?macro=true";
import { default as search2195ZwGtHrsCJ0DwGf7uO9CTnmaAqsByECRg9sdL4X8Meta } from "/app/apps/nuxt/pages/search.vue?macro=true";
import { default as offlinebjUTzz2m3pxWOOb6RQEwINP7wsF3WL7B7LAqeG1m9tEMeta } from "/app/apps/nuxt/pages/offline.vue?macro=true";
import { default as productNYR0ouUlAb8DHViG4iUPEahWeg0iGGo9zQClSXa6IRoMeta } from "/app/apps/nuxt/pages/product.vue?macro=true";
import { default as categoryEaVgH7CHZlL4KS65mLqjGNsgxT3uJ4_ic4NpqZ4TXYAMeta } from "/app/apps/nuxt/pages/category.vue?macro=true";
import { default as logindup8lboq2T7w8lYfUG_oO0sSRar18KKUCcSI595mnoUMeta } from "/app/apps/nuxt/pages/auth/login.vue?macro=true";
import { default as showZ_45Z4bTfFrlf3sX_45oB6JHQTU_45JJC6YMM2HFBkWHuOsdcMeta } from "/app/apps/nuxt/pages/brands/show.vue?macro=true";
import { default as indexEHM9G2llTV3aZxAYQtdPwM_457ADnflz_45bb1TwzKaE5EoMeta } from "/app/apps/nuxt/pages/brands/index.vue?macro=true";
import { default as register6lXSx3n2LATglzbgBVxaoj0iIFS_YZ7IUF5CQPegW28Meta } from "/app/apps/nuxt/pages/auth/register.vue?macro=true";
import { default as indexjPJSzjTlAVw5lHRdmA83kUa7KgAxYpDZZq2SICwp0lIMeta } from "/app/apps/nuxt/pages/checkout/index.vue?macro=true";
import { default as failedp9whj2mef2GXf16UPHfamTYAY7MOvCAAmZavwDrDpEgMeta } from "/app/apps/nuxt/pages/checkout/failed.vue?macro=true";
import { default as successWZr_45h6uSH07IgUwBKhzc9xo5l10WL4pUOLAydxxnZyYMeta } from "/app/apps/nuxt/pages/checkout/success.vue?macro=true";
import { default as indexZhP0eLFhmqFxEnepJLgiW_mkiAmy0LD_E3i49x9JpCMMeta } from "/app/apps/nuxt/pages/my-account/index.vue?macro=true";
import { default as validateKLWEgiutjQKg8lWp_45DPshtdkGOy28rGiz6yTan4G7CIMeta } from "/app/apps/nuxt/pages/checkout/validate.vue?macro=true";
import { default as ordersWtRM_C3nW6xdZBXvrOqeZ0qMq3_5KLWkSIZqaCF9n48Meta } from "/app/apps/nuxt/pages/my-account/orders.vue?macro=true";
import { default as reset_45password7iOFeGRGXc29eJBLmFUuqU0d1Ul9lbsklBXHaRiJZQ0Meta } from "/app/apps/nuxt/pages/auth/reset-password.vue?macro=true";
import { default as Stepl2_45QQNyFITvlS9kcYDxeTHXZj2i3wxmC_zzT2AfDVBYMeta } from "/app/apps/nuxt/pages/checkout/steps/Step.vue?macro=true";
import { default as addresses9v46ksdhtuLhiXCKG_45QgiEUjtQVltPMrSs4AHaCXDkYMeta } from "/app/apps/nuxt/pages/my-account/addresses.vue?macro=true";
import { default as set_45new_45passwordfFmAHlOgow1DGjtc9Zcdkj4hp3i7yNw1BJCayuvzno4Meta } from "/app/apps/nuxt/pages/auth/set-new-password.vue?macro=true";
import { default as PaypaljhwUzMTkTMVzFW39NTXWe66w3cCpARDM2VgPlFiCqv8Meta } from "/app/apps/nuxt/pages/checkout/gateways/Paypal.vue?macro=true";
import { default as reset_45password_45successHFVWZgrGsK0KGLREm8unpKawGZq_zrj0C1xau7ARYAwMeta } from "/app/apps/nuxt/pages/auth/reset-password-success.vue?macro=true";
import { default as MultisafepaytEhGL_45y0i_nYTOFmZaDfxrgWw_VYRIyj9paTFnZococMeta } from "/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue?macro=true";
import { default as CheckoutAccountQr0HE1RdcSULktrI4sJ1ipa4ZHAJiKY3A7_455tozAUk4Meta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue?macro=true";
import { default as CheckoutAddressJuKGg2j9kkX0wc4QZfcVCiA_00sukDmpNbVOl3NNSfsMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue?macro=true";
import { default as CheckoutMessagedOLoJev03ZuYQeH4DeDUjav1mJZh0RX2o3fxzcL31U0Meta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue?macro=true";
import { default as CheckoutPaymentxgSGB0lU7x4k32WuZxl6JUJk7CfSx6jpfZWnfWTOOHkMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue?macro=true";
import { default as CheckoutDeliveryrw14uMi_45uiKYADkjpYMoy1B6SPCRG8ZpJeKQWnBGA_45QMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue?macro=true";
import { default as CheckoutVouchersThpC26IrhiddQE61Vmhafj8zGmPrVfUHAcbIrKlXiiIMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue?macro=true";
import { default as component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCsMeta } from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCs } from "/app/node_modules/.pnpm/nuxt@3.16.2_@types+node@20.17.24_eslint@8.57.1_rollup@4.37.0_typescript@5.8.2_vite@6.2.3_vue-tsc@2.2.8/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "cart___de",
    path: "/de/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "cart___it",
    path: "/it/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "cart___es",
    path: "/es/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "cart___fr",
    path: "/fr/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "home___en",
    path: "/en/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "home___de",
    path: "/de/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "home___it",
    path: "/it/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "home___es",
    path: "/es/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "home___fr",
    path: "/fr/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "page___en",
    path: "/en/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "page___de",
    path: "/de/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "page___it",
    path: "/it/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "page___es",
    path: "/es/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "page___fr",
    path: "/fr/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "error___en",
    path: "/en/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "error___de",
    path: "/de/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "error___it",
    path: "/it/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "error___es",
    path: "/es/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "error___fr",
    path: "/fr/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  },
  {
    name: "search___it",
    path: "/it/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  },
  {
    name: "offline___en",
    path: "/en/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "offline___de",
    path: "/de/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "offline___it",
    path: "/it/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "offline___es",
    path: "/es/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "offline___fr",
    path: "/fr/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "product___en",
    path: "/en/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "product___de",
    path: "/de/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "product___it",
    path: "/it/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "product___es",
    path: "/es/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "product___fr",
    path: "/fr/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "category___en",
    path: "/en/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "category___de",
    path: "/de/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "category___it",
    path: "/it/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "category___es",
    path: "/es/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "category___fr",
    path: "/fr/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___de",
    path: "/de/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___it",
    path: "/it/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___es",
    path: "/es/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___fr",
    path: "/fr/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "brands-show___en",
    path: "/en/brands/show",
    component: () => import("/app/apps/nuxt/pages/brands/show.vue")
  },
  {
    name: "brands-show___de",
    path: "/de/brands/show",
    component: () => import("/app/apps/nuxt/pages/brands/show.vue")
  },
  {
    name: "brands-show___it",
    path: "/it/brands/show",
    component: () => import("/app/apps/nuxt/pages/brands/show.vue")
  },
  {
    name: "brands-show___es",
    path: "/es/brands/show",
    component: () => import("/app/apps/nuxt/pages/brands/show.vue")
  },
  {
    name: "brands-show___fr",
    path: "/fr/brands/show",
    component: () => import("/app/apps/nuxt/pages/brands/show.vue")
  },
  {
    name: "brands___en",
    path: "/en/brands",
    component: () => import("/app/apps/nuxt/pages/brands/index.vue")
  },
  {
    name: "brands___de",
    path: "/de/brands",
    component: () => import("/app/apps/nuxt/pages/brands/index.vue")
  },
  {
    name: "brands___it",
    path: "/it/brands",
    component: () => import("/app/apps/nuxt/pages/brands/index.vue")
  },
  {
    name: "brands___es",
    path: "/es/brands",
    component: () => import("/app/apps/nuxt/pages/brands/index.vue")
  },
  {
    name: "brands___fr",
    path: "/fr/brands",
    component: () => import("/app/apps/nuxt/pages/brands/index.vue")
  },
  {
    name: "auth-register___en",
    path: "/en/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "auth-register___de",
    path: "/de/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "auth-register___it",
    path: "/it/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "auth-register___es",
    path: "/es/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "auth-register___fr",
    path: "/fr/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout___de",
    path: "/de/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout___it",
    path: "/it/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout___es",
    path: "/es/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout___fr",
    path: "/fr/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout-failed___en",
    path: "/en/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-failed___de",
    path: "/de/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-failed___it",
    path: "/it/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-failed___es",
    path: "/es/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-failed___fr",
    path: "/fr/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-success___en",
    path: "/en/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "checkout-success___de",
    path: "/de/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "checkout-success___it",
    path: "/it/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "checkout-success___es",
    path: "/es/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "checkout-success___fr",
    path: "/fr/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "my-account___en",
    path: "/en/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "my-account___de",
    path: "/de/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "my-account___it",
    path: "/it/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "my-account___es",
    path: "/es/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "my-account___fr",
    path: "/fr/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "checkout-validate___en",
    path: "/en/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "checkout-validate___de",
    path: "/de/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "checkout-validate___it",
    path: "/it/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "checkout-validate___es",
    path: "/es/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "checkout-validate___fr",
    path: "/fr/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "my-account-orders___en",
    path: "/en/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "my-account-orders___de",
    path: "/de/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "my-account-orders___it",
    path: "/it/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "my-account-orders___es",
    path: "/es/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "my-account-orders___fr",
    path: "/fr/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___de",
    path: "/de/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___it",
    path: "/it/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___es",
    path: "/es/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___fr",
    path: "/fr/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "checkout-steps-Step___en",
    path: "/en/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "checkout-steps-Step___de",
    path: "/de/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "checkout-steps-Step___it",
    path: "/it/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "checkout-steps-Step___es",
    path: "/es/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "checkout-steps-Step___fr",
    path: "/fr/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "my-account-addresses___en",
    path: "/en/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-addresses___de",
    path: "/de/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-addresses___it",
    path: "/it/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-addresses___es",
    path: "/es/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-addresses___fr",
    path: "/fr/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "auth-set-new-password___en",
    path: "/en/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "auth-set-new-password___de",
    path: "/de/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "auth-set-new-password___it",
    path: "/it/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "auth-set-new-password___es",
    path: "/es/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "auth-set-new-password___fr",
    path: "/fr/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "checkout-gateways-Paypal___en",
    path: "/en/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "checkout-gateways-Paypal___de",
    path: "/de/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "checkout-gateways-Paypal___it",
    path: "/it/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "checkout-gateways-Paypal___es",
    path: "/es/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "checkout-gateways-Paypal___fr",
    path: "/fr/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "auth-reset-password-success___en",
    path: "/en/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "auth-reset-password-success___de",
    path: "/de/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "auth-reset-password-success___it",
    path: "/it/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "auth-reset-password-success___es",
    path: "/es/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "auth-reset-password-success___fr",
    path: "/fr/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___en",
    path: "/en/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___de",
    path: "/de/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___it",
    path: "/it/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___es",
    path: "/es/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___fr",
    path: "/fr/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___en",
    path: "/en/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___de",
    path: "/de/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___it",
    path: "/it/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___es",
    path: "/es/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___fr",
    path: "/fr/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___en",
    path: "/en/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___de",
    path: "/de/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___it",
    path: "/it/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___es",
    path: "/es/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___fr",
    path: "/fr/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___en",
    path: "/en/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___de",
    path: "/de/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___it",
    path: "/it/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___es",
    path: "/es/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___fr",
    path: "/fr/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___en",
    path: "/en/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___de",
    path: "/de/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___it",
    path: "/it/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___es",
    path: "/es/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___fr",
    path: "/fr/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___en",
    path: "/en/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___de",
    path: "/de/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___it",
    path: "/it/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___es",
    path: "/es/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___fr",
    path: "/fr/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___en",
    path: "/en/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___de",
    path: "/de/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___it",
    path: "/it/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___es",
    path: "/es/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___fr",
    path: "/fr/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCsMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCs
  },
  {
    name: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCsMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCs
  },
  {
    name: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCsMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCs
  },
  {
    name: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCsMeta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCs
  },
  {
    name: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCsMeta?.name,
    path: "/it-sitemap.xml",
    component: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCs
  },
  {
    name: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCsMeta?.name,
    path: "/es-sitemap.xml",
    component: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCs
  },
  {
    name: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCsMeta?.name,
    path: "/fr-sitemap.xml",
    component: component_45stubcMiCU8bUrRxq1Yy0od97kRyk2GNw_45hN_NKVX_N6cwCs
  }
]